import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HiContent from '../components/hi-content'

const HiTemplate = ({ data, location }) => {
  const hi = data.markdownRemark
  const { frontmatter, htmlAst } = hi
  const { title, description } = frontmatter

  return (
    <Layout
      location={location}
      title="Hello"
      preposition="from"
      footer={false}
    >
      <SEO
        title={title || "Hi 👋🏻"}
        description={description || "Here's why I think you should hire me."}
      />
      <div className="max-w-3xl mx-auto px-4 py-12">
        <HiContent content={htmlAst} />
      </div>
    </Layout>
  )
}

export default HiTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id } ) {
      htmlAst
      frontmatter {
        title
        description
      }
    }
  }
`
